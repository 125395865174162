import React from "react";
import "./style.scss";
import { Link } from "gatsby";

//icons
import { IconContext } from "react-icons";
import { GiCookie } from "react-icons/gi";

class CookiePopup extends React.Component {

    //type declarations
    cookiePopup: HTMLElement;
    
    constructor(props) {
        super(props);
        this.cookiePopup = null;
    }

    hidePopup(){
        this.cookiePopup.classList.remove("show");
        localStorage.setItem("cookiePopup", "hidden");
    }

    componentDidMount() {
        if(localStorage.getItem("cookiePopup")==null || localStorage.getItem("cookiePopup")==="visible"){
            this.cookiePopup.classList.add("show");
            localStorage.setItem("cookiePopup", "visible");
        }
    }

    render() {
        return (
            <>  
            <IconContext.Provider value={{color: "#856404"}}>
                <div className="cookiePopup-parent container-fluid fixed-top mt-3 text-justify">
                    <div className="row">
                        <div className="col-3"></div>
                        <div className="col d-none d-lg-block"></div>
                        <div className="col">
                            <div className="alert alert-warning fade" ref={el => this.cookiePopup = el}><GiCookie /> We use cookies to improve your experience. If you continue to browse, you accept their use.<button type="button" className="btn cookie-btn ml-1" onClick={() => this.hidePopup()}>OK</button> - <Link to="/privacy-policy" className="cookie-link">Policy</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </IconContext.Provider>
            </>
        )
    }
}

export default CookiePopup