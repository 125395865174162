import React from "react";
import "./style.scss";
import { gsap, Power3 } from "gsap";

class AbWatermark extends React.Component {

    introTimeline: GSAPTimeline;
    introContainer: HTMLElement;
    introText1: HTMLElement;

    constructor(props) {
        super(props);
        this.introTimeline = gsap.timeline({paused: true});
        this.introContainer = null;
        this.introText1 = null;
    }

    componentDidMount() {

        //animations
        gsap.set(this.introText1, {rotationY:100, opacity:0});

        this.introTimeline
        .to(this.introText1, {
            rotationY:0, transformOrigin:"50% 0", opacity:1, 
            ease:Power3.easeOut, duration:0.5
        })
        
        this.introTimeline.play()
    }

    render() {
        return (
            <>
                <div className="abWatermark-parent position-fixed ml-2 pt-2 pb-2 pr-1 pl-1" ref={el => this.introContainer = el}>
                    <p className="font-weight-light m-0" ref={el => this.introText1 = el}>Andrea Balbo {new Date().getFullYear()}</p>
                </div>
            </>
        )
    }
}

export default AbWatermark