import React from "react";
import "./style.scss";
import { gsap, Power3 } from "gsap";

class AbReveal extends React.Component {

    //type declarations
    introTimeline: GSAPTimeline;
    introContainer: HTMLElement;
    introImg1: HTMLElement;
    introText2: HTMLElement;

    constructor(props) {
        super(props);
        this.introTimeline = gsap.timeline({paused: true});
        this.introContainer = null;
        this.introImg1 = null;
        this.introText2 = null;
    }

    componentDidMount() {

        //animations
        gsap.set(this.introImg1, {y:"180%", rotation:20});
        gsap.set(this.introText2, {y:"180%", rotation:20});

        this.introTimeline
        .from(this.introContainer, {
            scaleX:0, scaleY:0, transformOrigin:"0 100%", 
            ease:Power3.easeInOut, duration:0.4
        })
        .to(this.introImg1, {
            y:0, rotation:0, transformOrigin:"0 100%", 
            ease:Power3.easeOut, duration:0.5
        })
        .to(this.introText2, {
            y:0, rotation:0, transformOrigin:"0 100%", 
            ease:Power3.easeOut, duration:0.5
        })
        .to(this.introImg1, {
            y:"180%",
            ease:Power3.easeIn, duration:0.2
        }, "+=0.5")
        .to(this.introText2, {
            y:"180%",
            ease:Power3.easeIn, duration:0.2
        })
        .to(this.introContainer, {
            scaleX:0, transformOrigin:"100% 0",
            ease:Power3.easeInOut, duration:0.5, delay:0.5
        })
        .to(this.introContainer, {
            display: "none"
        });

        //this.introTimeline.play() //manually play

        //play one time per tab - set body overflow
        if(sessionStorage.getItem("introTimeline")==null){
            sessionStorage.setItem("introTimeline", "Already Played");
            document.body.style.overflow = "hidden";
            this.introTimeline.play()
            this.introTimeline.eventCallback("onComplete", function() {
                document.body.style.overflow = "auto";
            });
        }
        
    }

    render() {
        return (
            <>
                <div className="abReveal-parent d-flex align-items-stretch justify-content-center text-light" ref={el => this.introContainer = el}>
                    <div className="d-flex flex-column justify-content-center align-items-center">
                        <span style={{overflow: "hidden"}}><img src="/assets/imgs/logo-ab.svg" alt="Logo Andrea Balbo" ref={el => this.introImg1 = el} /></span>
                        <span style={{overflow: "hidden"}}><h1 className="display-6" ref={el => this.introText2 = el}>Andrea Balbo</h1></span>
                    </div>
                </div>
            </>
        )
    }
}

export default AbReveal